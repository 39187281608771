.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    width: 540px;
    max-width: 100%;

    @media (max-width: 1100px) {
        gap: 32px;
    }
}

.contentContainerHorizontal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    width: 736px;
    max-width: 100%;
}

.badge {
    color: var(--main-gray);
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 0.4px;

    @media (max-width: 1100px) {
        font-size: 13px;
    }
}

.insideContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    @media (max-width: 1100px) {
        gap: 16px;

        img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 130px;
            width: auto;
        }
    }
}

.textDataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.withMarginLeft {
    align-items: flex-start;
    margin-left: 230px;
}

.header {
    margin: 0;
    font-size: 30px;
    line-height: 36px;
    font-family: "gilroy7", sans-serif;

    @media (max-width: 1100px) {
        font-size: 16px;
        line-height: 19px;
    }
}

.preText {
    font-family: "gilroy7", sans-serif;
    margin: 0;
    letter-spacing: 0.4px;
    font-size: 16px;
    line-height: 26px;
    color: var(--main-gray);

    @media (max-width: 1100px) {
        font-size: 16px;
        line-height: 19px;
    }
}

.text {
    margin: 0;
    letter-spacing: 0.4px;
    font-size: 16px;
    line-height: 26px;
    color: var(--main-gray);

    @media (max-width: 1100px) {
        font-size: 13px;
        line-height: 130%;
    }
}

.textStart {
    align-self: flex-start;
}

.helperText {
    margin: 0;
    letter-spacing: 0.4px;
    font-size: 16px;
    line-height: 26px;
    color: #5D91FF;
    font-family: "gilroy7", sans-serif;

    @media (max-width: 1100px) {
        font-size: 13px;
        line-height: 130%;
    }
}

.textCenter {
    text-align: center;
    align-self: center;
}

.absoluteImage {
    position: absolute;
    top: 85px;
    left: 30px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1100px) {
        flex-direction: column;
        gap: 18px;
    }
}

.nextButtonBlock {
    width: 244px;
    height: 56px;
    margin-left: auto;

    &:hover {
        @media (min-width: 1101px) {
            button {
                width: 244px;
                height: 56px;
                transform: scale(0.8);
                box-shadow: 0 4px 7px rgba(93, 145, 255, 0.44);
            }

            img {
                right: 54px;
            }
        }
    }

    @media (max-width: 1100px) {
        width: 100%;
        height: 44px;
    }
}

.nextButton {
    width: 100%;
    height: 100%;
    background-color: #5D91FF;
    border: none;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 18px;
    line-height: 20px;
    font-family: "gilroy5", sans-serif;
    position: relative;
    cursor: pointer;
    transition: all .2s ease-out;
    will-change: transform;
    height: 55px;

    img {
        position: absolute;
        top: 50%;
        right: 22px;
        transform: translateY(-50%);
        transition: all .3s ease-out;
    }

    @media (max-width: 1100px) {

        align-items: center;
        height: 44px;

        img {
            left: 50%;
            transform: translate(calc(-50% + 60px), -50%);
        }
    }
}

.skipButton {
    width: 180px;
    height: 56px;
    border: 1px solid #DEE4F4 !important;
    border-radius: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    opacity: 0.4;
    color: var(--main-gray);
    font-size: 18px;
    line-height: 20px;
    font-family: "gilroy5", sans-serif;
    cursor: pointer;

    @media (max-width: 1100px) {
        width: 100%;
    }
}

.fullWidth {
    width: 100%;
}
