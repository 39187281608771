.popupContent {
    background-color: var(--white);
    border-radius: var(--br-modal);
    box-shadow: var(--sh-modal);
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
    max-height: 96vh;
    max-height: calc(var(--vh, 1vh) * 96);

    @media (max-width: 1100px) {
        padding: 18px;
    }
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.mainModal {
    width: min(558px, 90%);
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: #050432;
    position: relative;
    box-sizing: border-box;
}

.mainModalLogo {
    font-family: "CocoSharp", sans-serif;
    font-size: 20px;
    line-height: 36px;
    white-space: nowrap;
    text-shadow: 0 3.4433px 35.2938px rgba(0, 178, 255, 0.52);
    width: max-content;
    margin: 0;
}

.mainModalLogoWhite {
    color: var(--white);
}

.mainModalLogoLight {
    color: #7CD8FF;
}

.mainModalLogoBlue {
    color: #00B2FF;
}

.mainModalTopBlock {
    display: flex;
    flex-direction: column;
}

.mainModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        cursor: pointer;
    }
}

.mainModalPreHeader {
    font-size: 15px;
    line-height: 17px;
    color: var(--main-gray);
}

.mainModalBottomBlock {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.mainModalLink {
    font-family: "gilroy5", sans-serif;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
    color: var(--white);
    transition: all .2s linear;
    width: max-content;
    text-decoration: none;

    hr {
        border: none;
        width: 0;
        margin: 0 auto;
        transition: width .2s;
        background-color: var(--white);
        height: 2px;
        border-radius: 5px;
    }

    &:hover {
        transform: scale(1.1, 1.1);
        text-shadow: 0 4px 41px #00B2FF;
        opacity: 0.9;

        hr {
            width: 100%;
        }
    }
}

.mainModalSecondImage {
    position: absolute;
    bottom: 22px;
    z-index: -2;
    left: 50%;
    transform: translateX(-50%);
    width: 192px;
}

.mainModalMainImage {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 262px;
}

.teamModal {
    width: min(508px, 90%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        gap: 18px;
    }
}

.teamError {
    font-size: 15px;
    line-height: 17px;
    color: var(--red);

    @media (max-width: 1100px) {
        font-size: 12px;
        line-height: 14px;
        margin-top: -14px;
    }
}

.teamCancelButton {
    @media (max-width: 1100px) {
        margin-top: -8px;
    }
}

.personContainer {
    width: 100%;
    height: 52px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 6px;

    @media (max-width: 1100px) {
        background-color: var(--bg-main-gray);
        height: 62px;
        border-radius: 15px;
    }
}

.personInsideContainer {
    background-color: var(--bg-main-gray);
    padding-left: 14px;
    padding-right: 15px;
    width: 290px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-radius: var(--br-modal);

    .personInsideContainerBlock {
        display: flex;
        gap: 16px;
    }

    @media (max-width: 1100px) {
        padding-right: 0;
        padding-left: 10px;
        max-width: 200px;

        .personInsideContainerBlock {
            display: flex;
            gap: 4px;
            align-items: center;

            img {
                margin-right: 12px;
            }
        }
    }
}

.personCEOData {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.personData {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
}

.personName {
    font-family: "gilroy5", sans-serif;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    .salary {
        margin-left: 8px;
        font-size: 12px;
        line-height: 14px;
    }
}

.personCEO {
    font-family: "gilroy5", sans-serif;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
}

.personAdded {
    color: var(--bg-dark-green);
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy6", sans-serif;
}

.deletedPerson {
    color: var(--red);
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy6", sans-serif;
}

.personCountActionsBlock {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;

    @media (max-width: 1100px) {
        min-width: 128px;
        padding-right: 10px;
        box-sizing: border-box;
    }

    @media (max-width: 370px) {
        min-width: 118px;
    }
}

.personAdd {
    color: var(--blue);
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy6", sans-serif;
    margin: 0 20px;

    @media (max-width: 1100px) {
        color: var(--blue);
        font-size: 18px;
        line-height: 21px;
        font-family: "gilroy6", sans-serif;
        margin: 0;
    }
}

.personActionHide {
    opacity: 0;
    z-index: -1;
}

.personActionMinus {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy6", sans-serif;
    color: #A6ADBF;

    @media(max-width: 1100px) {
        position: inherit;
        width: 42px;
        height: 42px;
        background-color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--br-small);
        margin-right: auto;
    }
}

.personActionPlus {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy6", sans-serif;
    color: #A6ADBF;

    @media(max-width: 1100px) {
        position: inherit;
        width: 42px;
        height: 42px;
        background-color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--br-small);
        margin-left: auto;
    }
}

.personSalary {
    font-family: "gilroy5", sans-serif;
    font-size: 16px;
    line-height: 19px;
    width: 50px;

    @media (max-width: 1100px) {
        font-size: 13px;
        line-height: 15px;
    }
}

.modalHeader {
    font-size: 22px;
    line-height: 27px;
    margin: 0;
    font-family: "gilroy7", sans-serif;

     @media (max-width: 1100px) {
        font-size: 18px;
        line-height: 22px;
         display: flex;
         flex-direction: column;
         gap: 4px;
    }
}

.modalText {
    font-size: 15px;
    line-height: 17px;
    color: var(--main-gray);

    @media (max-width: 1100px) {
        font-size: 12px;
        line-height: 14px;
        margin-top: -14px;
    }
}

.marketModal {
    width: min(595px, 90%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;

    @media(max-width: 1100px) {
        gap: 4px;
    }
}

.stageModal {
    width: min(844px, 90%);
    background-color: var(--white);
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.addTopMargin {
    top: calc(50% + 30px);
}

.stageHeader {
    padding: 22px 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #FAFAFA;
    border-top-left-radius: var(--br-modal);
    border-top-right-radius: var(--br-modal);

    img {
        cursor: pointer;

        @media (max-width: 1100px) {
            width: 18px;
        }
    }

    @media (max-width: 1100px) {
        padding: 18px;
        background-color: var(--white);
    }
}

.stageTitle {
    font-family: "gilroy7", sans-serif;
    font-size: 22px;
    line-height: 27px;
    margin: 0;

    @media (max-width: 1100px) {
        font-size: 18px;
        line-height: 22px;
    }
}

.stageMainBlock {
    padding: 30px;
    overflow-y: auto;
    scrollbar-gutter: stable;

    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--violet);
    }

    &::-webkit-scrollbar {
        width: 8px;

        @media (max-width: 1100px) {
            width: 0;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 100px;
        background-color: var(--blue);
        background-clip: content-box;
    }

    @media (max-width: 1100px) {
        padding: 0 18px 18px 18px;
    }
}

.stageBlock {
    gap: 10px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1100px) {
        gap: 8px;
    }
}

.stageBlockHeader {
    color: var(--main-gray);
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy6", sans-serif;
    margin: 0;

    @media (max-width: 1100px) {
        font-size: 14px;
        line-height: 17px;
    }
}

.stageHint {
    color: var(--red);
    font-size: 15px;
    line-height: 22px;
    font-family: "gilroy6", sans-serif;
    margin-bottom: -8px;

    @media (max-width: 1100px) {
        font-size: 14px;
    }
}

.stageBlockDescriptionText {
    color: var(--main-gray);
    font-size: 15px;
    line-height: 22px;

    @media (max-width: 1100px) {
        font-size: 14px;
    }
}

.stageHr {
    width: 100%;
    margin: 12px 0;
    border: none;
    height: 1px;
    background-color: #DEE4F4;

    @media (max-width: 1100px) {
        margin: 18px 0;
    }
}

.stagePersonList {
    padding-left: 17px;
    margin: 0;
}

.stagePerson {
    font-size: 15px;
    line-height: 22px;
    color: var(--blue);
    font-family: "gilroy5I", sans-serif;;

    @media (max-width: 1100px) {
        font-size: 14px;
    }
}

.stageBlockTaskText {
    color: var(--blue);
    font-size: 15px;
    line-height: 22px;
    font-family: "gilroy6", sans-serif;

    @media (max-width: 1100px) {
        font-size: 14px;
    }
}

.positiveText {
    color: var(--green);
    font-size: 15px;
    line-height: 22px;
    font-family: "gilroy6", sans-serif;

    @media (max-width: 1100px) {
        font-size: 14px;
    }
}

.negativeText {
    color: var(--red);
    font-size: 15px;
    line-height: 22px;
    font-family: "gilroy6", sans-serif;

    @media (max-width: 1100px) {
        font-size: 14px;
    }
}

.modalMarketHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    img {
        cursor: pointer;
    }
}

.modalMarketHeaderValue {
    font-size: 22px;
    line-height: 27px;
    font-family: "gilroy7", sans-serif;
    color: var(--bg-light-green);
    margin-left: 25px;

    @media (max-width: 1100px) {
        font-size: 17px;
        line-height: 21px;
        margin-left: 0;
    }
}

.modalMarketText {
    font-size: 15px;
    line-height: 22px;
    color: var(--main-gray);

    @media (max-width: 1100px) {
        font-size: 12px;
        line-height: 14px;
    }
}

.modalMarketTable {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 14px;

    @media (max-width: 1100px) {
        gap: 18px;
    }
}

.modalMarketTableGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.modalMarketTableHeader {
    padding: 0 20px;
    font-size: 16px;
    line-height: 19px;
    font-family: "gilroy5", sans-serif;
    color: var(--main-gray);
    background-color: var(--white);

    @media (max-width: 1100px) {
        font-size: 13px;
        line-height: 15px;
        padding: 0;

        span[aria-details="Capital"] {
            justify-self: flex-end;
        }
    }
}

.modalMarketTableGrayLine {
    padding: 17px 20px;
    background-color: var(--bg-main-gray);
    border-radius: var(--br-modal);

    @media (max-width: 1100px) {
        padding: 14px 10px 14px 14px;
    }
}

.modalMarketTableLine {
    padding: 17px 20px;
    background-color: var(--white);

    @media (max-width: 1100px) {
        padding: 14px 10px 14px 14px;
    }
}

.modalMarketTableTitle {
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy5", sans-serif;

    @media (max-width: 1100px) {
        font-size: 13px;
        line-height: 15px;
    }
}

.modalMarketTableClients {
    font-size: 16px;
    line-height: 19px;
    color: var(--violet);
    font-family: "gilroy5", sans-serif;

    @media (max-width: 1100px) {
        font-size: 13px;
        line-height: 15px;
    }
}

.modalMarketTableCapital {
    font-size: 16px;
    line-height: 19px;
    color: var(--bg-light-green);
    font-family: "gilroy5", sans-serif;

    @media (max-width: 1100px) {
        font-size: 13px;
        line-height: 15px;
    }
}

.modalMarketTableCompetitors {
    font-size: 16px;
    line-height: 19px;
    color: var(--red);
    font-family: "gilroy5", sans-serif;

    @media (max-width: 1100px) {
        font-size: 13px;
        line-height: 15px;
    }
}

.modalMarketHr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: #707070;
    opacity: 0.15;
    margin: 3px 0 10px 0;

    @media (max-width: 1100px) {
         margin: 0
    }
}

.metricsModal {
    padding: 14px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 280px;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 11;
    transform: translateY(0);
}

.metricsModalLeft {
    left: auto;
    right: 0;
}

.metricsModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        align-self: flex-start;
        cursor: pointer;
    }
}

.metricsModalName {
    width: 80%;
    font-family: "gilroy6", sans-serif;
    font-size: 22px;
    line-height: 26px;
}

.metricsModalFormula {
    font-family: "gilroy5I", sans-serif;
    font-size: 15px;
    line-height: 18px;
}

.metricsModalText {
    font-family: "gilroy5", sans-serif;
    font-size: 15px;
    line-height: 22px;
}

.cancelTaskModal {
    width: min(508px, 90%);
    gap: 24px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        gap: 18px;
    }
}

.cancelTaskHeader {
    margin: 0;
    font-family: "gilroy7", sans-serif;
    font-size: 22px;
    line-height: 27px;

    @media (max-width: 1100px) {
        font-size: 18px;
        line-height: 22px;
    }
}

.cancelTaskBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 24px;
    background-color: var(--bg-active-green);
    border: 1px solid var(--bg-light-green);
    border-radius: 12px;

    @media (max-width: 1100px) {
        padding: 18px;
    }
}

.cancelTaskBlockHeader {
    margin: 0;
    font-family: "gilroy7", sans-serif;
    font-size: 17px;
    line-height: 21px;

    @media (max-width: 1100px) {
        font-size: 13px;
        line-height: 16px;
    }
}

.cancelTaskInsideBlocks {
    gap: 10px;
    display: flex;

    @media (max-width: 1100px) {
        flex-direction: column;
    }
}

.cancelTaskInsideBlock {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 183px;

    @media (max-width: 1100px) {
        width: 100%
    }
}

.cancelTaskInsideBlockName {
    color: var(--main-gray);

    @media (max-width: 1100px) {
        line-height: 14px;
    }
}

.cancelTaskInsideTurnsBlock {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    background-color: var(--bg-middle-green);
    border-radius: var(--br-main);
}

.cancelTaskInsideMetricsBlock {
    padding: 8px 12px;
    background-color: var(--bg-middle-green);
    border-radius: var(--br-main);
}

.cancelTaskInsideMetricsInsideBlock {
    display: flex;
    justify-content: space-between;
}

.cancelTaskInsideBlockLabel {
    font-family: "gilroy6", sans-serif;

    @media (max-width: 1100px) {
        line-height: 14px;
    }
}

.cancelTaskInsideBlockTurnsValue {
    color: var(--blue);
    font-family: "gilroy6", sans-serif;

    @media (max-width: 1100px) {
        line-height: 14px;
    }
}

.cancelTaskInsideBlockMetricsValue {
    color: var(--green);
    font-family: "gilroy6", sans-serif;

    @media (max-width: 1100px) {
        line-height: 14px;
    }
}

.cancelTaskButtonsBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loader {
    z-index: 9999;
    background-color: unset;
    box-shadow: none;
}

.loaderOverlay {
    z-index: 9999999;
}

.slidersModal {
    max-width: min(1240px, 90%);
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 4px solid var(--white);
    box-sizing: border-box;
    overflow-y: auto;

    @media (max-width: 1100px) {
        scrollbar-gutter: stable;
    }


    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--violet);
    }

    &::-webkit-scrollbar {
        width: 8px;

        @media (max-width: 1100px) {
            width: 0;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
        margin-left: 10px;
        border: 2px solid transparent;
        border-radius: 100px;
        background-color: var(--blue);
        background-clip: content-box;
    }
}

.slidersEmptyModal {
    max-width: min(440px, 90%);
    box-sizing: border-box;
}

.slidersEmptyModalTopBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.slidersModalTopBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px 10px 30px;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: var(--white);

    img {
        cursor: pointer;
    }

    @media (max-width: 1100px) {
        padding: 24px 24px 18px 24px;
    }
}

.mainModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;


}

.slidersModalHeaderName {
    margin: 0;
    font-family: "gilroy7", sans-serif;
    font-size: 22px;
    line-height: 27px;

    span {
        color: var(--green);
        margin-left: 12px;
    }

    @media(max-width: 1100px) {
        font-size: 18px;
        line-height: 22px;
    }
}

.slidersModalText {
    padding: 0 30px;
    font-size: 15px;
    line-height: 17px;
    color: var(--main-gray);

    @media(max-width: 1100px) {
        font-size: 12px;
        line-height: 14px;
        padding: 0 24px;
    }
}

.slidersContainer {
    display: flex;
    gap: 30px;
    overflow-x: auto;
    scrollbar-gutter: stable;

    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--violet);
    }

    &::-webkit-scrollbar {
        width: 8px;

        @media (max-width: 1100px) {
            width: 0;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 100px;
        background-color: var(--blue);
        background-clip: content-box;
    }
}

.mentorsSlidersContainer {
    padding: 64px 30px 30px 30px;
}

.investorsSlidersContainer {
    padding: 48px 30px 30px 30px;
}

.sliderBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 15px rgba(18, 62, 106, 0.15);
    border-radius: 15px;
    box-sizing: border-box;
    min-width: 534px;
    height: fit-content;

    &:hover .sliderBlockHeader{
        border-top: 3px solid #507BF6;
        border-left: 3px solid #507BF6;
        border-right: 3px solid #507BF6;
    }

    &:hover .sliderBlockMain{
        border-bottom: 3px solid #507BF6;
        border-left: 3px solid #507BF6;
        border-right: 3px solid #507BF6;
    }
}

.sliderBlockHeader {
    position: relative;
    background-color: #FAFAFA;
    padding: 21px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-top: 3px solid #FAFAFA;
    border-left: 3px solid #FAFAFA;
    border-right: 3px solid #FAFAFA;
    display: flex;
    flex-direction: column;
    gap: 14px;

    h4 {
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        font-family: "gilroy7", sans-serif;
    }

    span {
        font-size: 15px;
        line-height: 22px;
        font-family: "gilroy5I", sans-serif;
        color: var(--blue);
    }
}

.sliderBlockHeaderInvestors {
    height: 82px;

    span {
        width: 315px;
    }
}

.sliderBlockMentorsHeaderImage {
    position: absolute;
    right: 35px;
    bottom: 0;
    z-index: 1
}

.sliderBlockMain {
    height: -webkit-fill-available;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom: 3px solid white;
    border-left: 3px solid white;
    border-right: 3px solid white;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 150px 1px 55px 1px 230px;

    hr {
        width: 100%;
        border: none;
        height: 1px;
        background-color: #707070;
        opacity: 0.15;
        margin: 0;
    }
}

.sliderBlockMainInvestors {
    grid-template-rows: 300px 1px 55px 1px 55px 1px 110px;
}

.sliderBlockMainInvestorsActive {
    grid-template-rows: 300px 1px 55px 1px 55px 1px 55px 1px 110px;
}

.sliderBlockText {
    margin: 21px 22px 0 18px;
    font-size: 15px;
    line-height: 22px;
    color: var(--main-gray);
    grid-row-start: 1;
    grid-row-end: 2;
}

.sliderMentorsConditionsBlockWithValue {
    margin: 0 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sliderMentorsConditionsBlockWithValueWithoutMargin {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sliderDataBlockName {
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy6", sans-serif;
    color: var(--main-gray);

    @media (max-width: 1100px) {
        font-size: 16px;
        line-height: 19px;
        align-self: flex-start;
    }
}

.brainstormDataBlockName {
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy6", sans-serif;
    color: var(--main-gray);

    @media (max-width: 1100px) {
        font-size: 14px;
        line-height: 17px;
    }
}

.sliderDataBlockValue {
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy5", sans-serif;
    color: var(--blue);

    @media (max-width: 1100px) {
        font-size: 16px;
        line-height: 19px;
        text-align: end;
    }
}

.brainstormDataBlockValue {
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy5", sans-serif;
    color: var(--blue);

    @media (max-width: 1100px) {
        font-size: 14px;
        line-height: 16px;
    }
}

.sliderDataBlockValueGreen {
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy5", sans-serif;
    color: var(--bg-light-green);

    @media (max-width: 1100px) {
        font-size: 16px;
        line-height: 19px;
    }
}

.sliderMentorsConditionsBlockWithList {
    margin: 17px 21px 0 21px;
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.sliderMentorsConditionsBlockWithListWithoutMargin {
    margin: 17px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.sliderDataBlockItem {
    font-size: 15px;
    line-height: 22px;
    font-family: "gilroy5I", sans-serif;
    color: var(--blue);
    display: flex;
    align-items: center;
    gap: 15px;

    @media (max-width: 1100px) {
        font-size: 14px;
        line-height: 22px;
    }
}

.signed {
    position: absolute;
    right: 16px;
    bottom: 16px;
}

.confirmModal {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: min(508px, 90%);
    box-sizing: border-box;

    @media (max-width: 1100px) {
        gap: 18px;
    }
}

.confirmModalMainHeader {
    margin: 0;
    font-size: 22px;
    line-height: 27px;
    font-family: "gilroy7", sans-serif;

    @media (max-width: 1100px) {
        font-size: 18px;
        line-height: 22px;
    }
}

.confirmModalSecondHeader {
    margin: 0;
    font-size: 20px;
    line-height: 24px;
    font-family: "gilroy7", sans-serif;

    @media (max-width: 1100px) {
        font-size: 16px;
        line-height: 19px;
    }
}

.confirmModalButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.confirmModalInvestorData {
    display: grid;
    grid-template-rows: 55px 1px 55px 1px auto;
    margin-top: -17px;

    hr {
        width: 100%;
        border: none;
        height: 1px;
        background-color: #707070;
        opacity: 0.15;
        margin: 0;
    }
}

.confirmModalMentorData {
    hr {
        width: 100%;
        border: none;
        height: 1px;
        background-color: #707070;
        opacity: 0.15;
        margin: 17px 0 0 0;
    }
}

.hr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: #707070;
    opacity: 0.15;
    margin: -7px 0;
}

.brainstormHr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: #707070;
    opacity: 0.15;
    margin: -7px 0;

    @media (max-width: 1100px) {
        margin: 0;
    }
}

.brainstormModal {
    width: min(568px, 90%);
    gap: 24px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        gap: 18px;
    }
}

.brainstormModalText {
    font-size: 15px;
    line-height: 22px;
    color: var(--main-gray);
    margin: 0;

    @media (max-width: 1100px) {
        font-size: 14px;
    }
}

.brainstormModalDataBlock {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 1100px) {
        gap: 18px;
    }
}

.banksHeader {
    margin: 0 0 0 18px;
    font-size: 24px;
    line-height: 29px;
    font-family: "gilroy6", sans-serif;
    color: var(--main-gray);

    @media (max-width: 1100px) {
        margin: 0;
        font-size: 16px;
        line-height: 19px;
    }
}

.banksHr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: #707070;
    opacity: 0.15;
    margin: 0;
}

.banksHorLine {
    margin-bottom: 8px;
}

.sliderBlockHeaderBanks {
    height: 66px;
    padding-left: 18px;
    box-sizing: border-box;
    justify-content: center;
}

.sliderBlockBanksHeaderImage {
    width: fit-content;
}

.sliderBlockMainBanks {
    grid-template-rows: 327px 46px 1px 38px 38px 61px;

    .sliderMentorsConditionsBlockWithValue {
        margin-top: 17px;
    }

    .banksMultiplier {
        margin-bottom: 21px;
    }
}

.confirmModalBankData {
    hr {
        width: 100%;
        border: none;
        height: 1px;
        background-color: #707070;
        opacity: 0.15;
        margin: 17px 0;
    }
}

.filtersModal {
    width: min(90%, 375px);
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}



.filtersModalInsideBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;

        @media (max-width: 1100px) {
            width: 0;
        }
    }
}

.filtersModalMetricBlock {
    display: flex;
    padding: 9px 12px;
    position: relative;
    width: calc(100% - 24px);
}

.filtersModalMetricBlockData {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.filtersInfo {
    margin-top: -8px;
    opacity: 0;
    max-height: 0;
    transform: translateY(-20px);
    transition: all .3s ease-out;
    font-size: 14px;
    line-height: 20px;
}

.filtersModalActiveInfo {
    margin-top: 0;
    opacity: 1;
    max-height: 200px;
    transform: translateY(0);
    transition: all .3s ease-out;
}

.filtersModalMetric {
    color: var(--main-gray);
    font-size: 16px;
    line-height: 19px;
    font-family: "gilroy6", sans-serif;
    z-index: 1;
}

.filtersModalActiveMetric {
    color: var(--blue);
}

.filtersModalMetricInfo {
    position: absolute;
    right: 0;
    top: 18px;
    transform: translateY(-50%);
    z-index: 2;
}

.filtersModalButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
}

.filtersModalClearButton {
    font-size: 18px;
    line-height: 20px;
    font-family: "gilroy5", sans-serif;
    text-decoration-line: underline;
    width: 157px;
    background-color: inherit;
    border: none;
    color: var(--main-black)
}

.filtersModalApplyButton {
    background-color: #5D91FF;
    text-align: center;
    width: 157px;
    height: 100%;
    border-radius: 30px;
    border: none;
    color: var(--white);
    font-size: 18px;
    line-height: 20px;
    font-family: "gilroy5", sans-serif;
}

.metricsOverviewModal {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: min(375px, 90%);
    box-sizing: border-box;
}

.metricsOverviewModalHeader {
    padding: 22px 30px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1100px) {
        padding: 18px;
    }
}

.metricsOverviewModalHeadName {
    font-size: 18px;
    line-height: 21px;
    font-family: "gilroy6", sans-serif;
}

.metricsOverviewModalMainBlocksContainer {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
    //margin-right: -18px;
    //padding-right: 10px;
    //scrollbar-gutter: stable;

    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--violet);
    }

    &::-webkit-scrollbar {
        width: 8px;

        @media (max-width: 1100px) {
            width: 0;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 100px;
        background-color: var(--blue);
        background-clip: content-box;
    }

    @media (max-width: 1100px) {
        padding: 0 18px 18px 18px;
    }
}

.metricsOverviewModalInsideBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.metricsOverviewModalMainBlock {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
}

.metricsOverviewModalName {
    font-size: 16px;
    line-height: 19px;
    font-family: "gilroy6", sans-serif;
    max-width: calc(100% - 30px);
}

.metricsOverviewModalValue {
    font-size: 16px;
    line-height: 19px;
    font-family: "gilroy6", sans-serif;
    color: var(--blue);
}

.metricsOverviewModalIcon {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(360deg);
    transition: all .3s ease-out;
}

.metricsOverviewModalIconOpen {
    transform: rotate(180deg);
    transition: all .3s ease-out;
}

.metricsOverviewModalFormula {
    margin-top: -12px;
    opacity: 0;
    max-height: 0;
    font-size: 14px;
    line-height: 16px;
    font-family: "gilroy5", sans-serif;
    transform: translateY(-20px);
    transition: all .3s ease-out;
}

.metricsOverviewModalFormulaOpen {
    margin-top: 0;
    opacity: 1;
    max-height: 200px;
    transform: translateY(0);
    transition: all .3s ease-out;
}

.metricsOverviewModalText {
    margin-top: -12px;
    opacity: 0;
    font-size: 14px;
    line-height: 20px;
    max-height: 0;
    transform: translateY(-20px);
    transition: all .3s ease-out;
}

.metricsOverviewModalTextOpen {
    margin-top: 0;
    opacity: 1;
    max-height: 200px;
    transform: translateY(0);
    transition: all .3s ease-out;
}

.splide {
    z-index: 2;
    padding: 24px;

    ul[role="tablist"] {
        top: 275px;
        height: fit-content;
    }
}

.splideMentors {
    z-index: 2;
    padding: 24px;

    ul[role="tablist"] {
        top: 225px;
        height: fit-content;
    }
}

.splideSlide {
    background-color: var(--white);

    .splideSlideInsideContainer {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.investorsSplideTopBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 258px;
    justify-content: space-between;

    img {
        width: 160px;
        margin: auto;
    }

    div {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 12px;

        h4 {
            margin: 0;
            font-size: 20px;
            line-height: 24px;
            font-family: "gilroy7", sans-serif;
            text-align: center;
            display: flex;
            gap: 4px;

            img {
                height: 24px;
                width: auto;
            }
        }

        span {
            font-size: 15px;
            line-height: 22px;
            font-family: "gilroy5I", sans-serif;
            color: var(--blue);
            text-align: center;
        }
    }
}

.investorsSplideSecondBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    div {
        margin: 0;
    }
}

.investorsSplideLastBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h5 {
        margin: 0;
        font-size: 16px;
        line-height: 19px;
        font-family: "gilroy6", sans-serif;
    }

    span {
        font-size: 15px;
        line-height: 22px;
        color: var(--main-gray);
    }
}

.mentorsSplideTopBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 210px;
    justify-content: space-between;

    img {
        height: 160px;
        margin: auto;
    }

    h4 {
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        font-family: "gilroy7", sans-serif;
        text-align: center;
        margin-bottom: 12px;
        display: flex;
        gap: 4px;

        img {
            height: 24px;
            width: auto;
        }
    }
}

.intServError {
    display: flex;
    flex-direction: column;
    gap: 48px;

    div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;

        h2 {
            margin: 0;
            font-family: "gilroy7", sans-serif;
            font-size: 28px;
            line-height: 34px;
            color: black;
        }

        span {
            font-size: 18px;
            line-height: 21px;
            color: #232323;
        }
    }

    button {
        padding: 12px 19px;
        border-radius: 12px;
        box-shadow: 0px 8px 25px 0px #00000040;
        background: #232323;
        font-family: "HelveticaNeue7", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
    }
}

.loginModal {
    background: linear-gradient(180deg, #050432 0%, #01002F 100%);
    width: min(1512px, 90%);
    height: 90%;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 37px 33px;
    text-align: center;
    box-sizing: border-box;

    @media (max-width: 1150px) and (min-width: 1101px) {
        padding: 37px 5px
    }

    @media (max-width: 1100px) {
        width: min(372px, 100%);
        grid-template-columns: 1fr;
        padding: 42px 26px 33px 26px;
        height: auto;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    @media (max-width: 374px) {
        padding: 37px 20px;
    }
}

.loginMain {
    margin: auto;
    width: 360px;

    @media (max-width: 1100px) {
        width: 320px;
    }
}

.loginHeader {
    display: flex;
    align-items: center;
    margin-bottom: 78px;

    @media (max-width: 1100px) or (max-height: 850px) {
        margin-bottom: 36px;
    }

    .vl {
        height: 24px;
        width: 1px;
        background: rgba(255, 255, 255, 0.56);
        border-radius: 4px;
        border: none;
        margin-left: 18px;
        margin-right: 26px;

        @media(max-width: 1100px) {
            height: 18px;
        }
    }

    .loginHeaderName {
        font-size: 18px;
        line-height: 96.8%;
        color: var(--white);

        @media(max-width: 1100px) {
            font-size: 16px;
        }
    }
}

.loginBody {
    width: 350px;
    margin: 0 auto;
    gap: 48px;
    display: flex;
    flex-direction: column;

    @media(max-width: 1100px) {
        width: 100%;
        gap: 24px;
    }
}

.loginBodyHead {
    display: flex;
    flex-direction: column;
    gap: 18px;

    h2 {
        margin: 0;
        font-size: 48px;
        line-height: 32px;
        font-family: "gilroy8", sans-serif;
        color: var(--white);
    }

    span {
        font-size: 14px;
        line-height: 17px;
        font-family: "gilroy8", sans-serif;
        color: var(--white);

        .blue {
            color: #00B2FF;
            cursor: pointer;
        }
    }
}

.loginFormContainer {
    display: flex;
    flex-direction: column;
    gap: 14px;

    button {
        width: 100%;
        border-radius: 56px;
        height: 42px;
        font-size: 14px;
        line-height: 17px;
        font-family: "gilroy8", sans-serif;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 18px;
        gap: 16px;
        border: none;
        cursor: pointer;
    }

    .googleButton {
        background-color: var(--white);
        color: #31495D;
    }

    .facebookButton {
        background-color: #052979;
        color: var(--white);
    }
}

.loginBottomContainer {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.loginInfo {
    display: flex;
    gap: 18px;
    white-space: nowrap;
    align-items: center;

    hr {
        width: 100%;
        height: 2px;
        border: none;
        background-color: #D9D9D9;
        opacity: 0.45;
    }

    span {
        font-size: 14px;
        line-height: 17px;
        color: var(--white);
        font-family: "gilroy8", sans-serif;
    }
}

.loginInputContainer {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.loginButton {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #00B2FF;
    border-radius: 56px;
    border: none;
    margin-top: 10px;
    cursor: pointer;

    span {
        font-size: 14px;
        line-height: 17px;
        font-family: "gilroy8", sans-serif;
        color: var(--white);
    }
}

.carousel {
    max-height: calc(100vh - 150px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 150px);
    background-color: var(--white);
    border-radius: 26px;
    max-width: 820px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1400px) {
        max-width: 720px;
    }

    @media (max-width: 1280px) {
        max-width: 620px;
    }
}

.loginSplide {
    z-index: 2;
    cursor: pointer;

    .carouserImage {
        box-shadow: 0 39px 23px -20px rgb(5 4 50 / 17%);
        border-image-source: linear-gradient(to left, #040166, #050432);
        border-image-slice: 1;
        border: double 7px transparent;
        border-radius: 13px;
        background-image: linear-gradient(white, white), radial-gradient(circle at top left, #040166, #050432);
        background-origin: border-box;
        background-clip: content-box, border-box;
        margin-bottom: 110px;

        @media (max-height: 1250px) {
            margin-bottom: 80px;
        }

        @media (max-height: 1000px) {
            margin-bottom: 60px;
        }

        @media (max-height: 850px) {
            margin-bottom: 40px;
        }
      }
}

.carouserBlock {
    margin-bottom: 26px;
}

.carouserHeader {
    font-size: 24px;
    line-height: 24px;
    font-family: "CocoSharp", sans-serif;
    color: #050432;
    margin: 22px 0 32px 0;
}

.carouserText {
    max-width: 374px;
    margin: 0 auto;
    display: block;
    font-size: 18px;
    line-height: 22px;
    font-family: "CocoSharp", sans-serif;
    color: #050432;
}

.splideBanks {
    z-index: 2;
    padding: 24px;

    ul[role="tablist"] {
        top: 100px;
        height: fit-content;
    }
}

.banksSplideTopBlock {
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.rating {
    width: min(864px, 90%);
    box-sizing: border-box;
    position: relative;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--bg-main-gray);
    overflow-y: auto;

    @media (max-width: 1100px) {
        width: min(375px, 90%);
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--violet);
    }

    &::-webkit-scrollbar {
        width: 12px;

        @media (max-width: 1100px) {
            width: 0;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
        border: 3px solid transparent;
        border-radius: 100px;
        background-color: var(--blue);
        background-clip: content-box;
    }

    img {
        position: static;
        width: fit-content;
        //transform: rotate(-90deg);
        top: initial;
        left: initial;
        margin: -10px auto 0 auto;
        height: 200px;
        filter: drop-shadow(0px 14px 22px rgba(2, 1, 18, 0.17));

        @media (max-width: 1100px) {
            transform: initial;
        }
    }
}

.finish {
    width: min(412px, 90%);
    box-sizing: border-box;
    position: relative;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--bg-main-gray);
    overflow-y: auto;

    @media (max-width: 1100px) {
        width: min(412px, 90%);
    }

     &::-webkit-scrollbar-thumb:hover {
        background-color: var(--violet);
    }

    &::-webkit-scrollbar {
        width: 12px;

        @media (max-width: 1100px) {
            width: 0;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
        border: 3px solid transparent;
        border-radius: 100px;
        background-color: var(--blue);
        background-clip: content-box;
    }

    img {
        position: static;
        width: fit-content;
        //transform: rotate(-90deg);
        top: initial;
        left: initial;
        margin: -10px auto 0 auto;
        height: 200px;
        filter: drop-shadow(0px 14px 22px rgba(2, 1, 18, 0.17));

        @media (max-width: 1100px) {
            transform: initial;
        }
    }

    @media (max-width: 1100px) {
        width: min(375px, 90%);
        overflow-y: auto;
        gap: 24px;
        padding: 32px;

        &::-webkit-scrollbar {
            width: 0;
        }


    }
}

.finishHeadBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;

    h2 {
        margin: 0;
        font-size: 22px;
        line-height: 27px;
        font-family: "gilroy7", sans-serif;
    }

    span {
        font-size: 16px;
        line-height: 22px;

        .finishTextBold {
            font-family: "gilroy7", sans-serif;
            font-size: 22px;
        }
    }
}

.finishDataContainer {
    margin: 0 auto;
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;

    .finishDataBlock {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .finishDataText {
            font-family: "gilroy5", sans-serif;
            font-size: 18px;
            line-height: 21px;
        }

        .finishDataValue {
            font-family: "gilroy7", sans-serif;
            font-size: 22px;
            line-height: 27px;
            color: var(--blue);
        }
    }
}

.finishButtonsBlock {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: min(448px, 100%);
    box-sizing: border-box;
    gap: 12px;
    text-align: center;

    button {
        width: 100%;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "gilroy7", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: var(--white);
        border-radius: var(--br-main);
        box-shadow: var(--sh-main);
        cursor: pointer;
        border: none;
    }

    .finishRatingsButton {
        background-color: #FFBD00;
    }

    .finishStartButton {
        background-color: var(--bg-light-green);
    }

    .finishGoMainPage {
        text-decoration-line: underline;
        text-shadow: var(--sh-main);
        color: var(--main-black);
        font-size: 15px;
        line-height: 22px;
    }
}

.outOfMoney {
    background-color: var(--bg-main-gray);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    width: min(412px, 90%);
    box-sizing: border-box;

     @media (max-width: 1100px) {
        width: min(375px, 90%);
    }

    img {
        width: 200px;
        margin: 0 auto;
        filter: drop-shadow(0px 14px 22px rgba(2, 1, 18, 0.17));
    }
}

.ratingsTable {
    display: flex;
    flex-direction: column;
    gap: 18px;

    &::-webkit-scrollbar {
        width: 0;
    }

    @media (max-width: 1100px) {
        gap: 12px;
    }
}

.ratingsTableHeader {
    display: grid;
    grid-template-columns: 144px 220px 268px 1fr;
    font-family: "gilroy7", sans-serif;
    font-size: 18px;
    line-height: 22px;

    @media (max-width: 1100px) {
        grid-template-columns: 87px 87px 87px 1fr;
        font-size: 13px;
        line-height: 16px;
        font-family: "gilroy7", sans-serif;

        span {
            max-width: 60px;
        }
    }

    @media (max-width: 415px) {
        grid-template-columns: 60px 87px 87px 1fr;
    }

    @media (max-width: 380px) {
        grid-template-columns: 60px 77px 82px 1fr;
    }
}

.ratingsHr {
    width: calc(100% - 2px);
    margin: 0 auto;
    padding: 0;
    height: 0;
    background: rgba(66, 68, 77, 0.15);
    border: 1px solid rgba(66, 68, 77, 0.01);
}

.ratingsHrWithMargin {
    margin: -12px auto;
}

.ratingsTableLine {
    display: grid;
    grid-template-columns: 144px 220px 268px 1fr;
    font-size: 18px;
    line-height: 22px;

    @media (max-width: 1100px) {
        grid-template-columns: 87px 87px 87px 1fr;
        font-size: 14px;
        line-height: 17px;
    }

    @media (max-width: 415px) {
        grid-template-columns: 60px 87px 87px 1fr;
    }

    @media (max-width: 380px) {
        grid-template-columns: 60px 77px 82px 1fr;
    }
}

.ratingMyDataContainer {
    background-color: var(--white);
    display: grid;
    grid-template-columns: 144px 220px 268px 1fr;
    padding: 4px 12px;
    width: 100%;
    border-radius: 12px;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        grid-template-columns: 87px 87px 87px 1fr;
        padding: 0;
        background-color: inherit;
        border-radius: unset;
    }

    @media (max-width: 415px) {
        grid-template-columns: 60px 87px 87px 1fr;
    }

    @media (max-width: 380px) {
        grid-template-columns: 60px 77px 82px 1fr;
    }

    div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: flex-end;
    }

    .ratingMyDataText {
        font-size: 18px;
        line-height: 22px;
        font-family: "gilroy7", sans-serif;

        @media (max-width: 1100px) {
            font-size: 13px;
            line-height: 16px;
            max-width: 60px;
        }
    }

    .ratingMyDataValue {
        font-size: 22px;
        line-height: 27px;
        font-family: "gilroy7", sans-serif;
        color: var(--blue);

        @media (max-width: 1100px) {
            font-size: 16px;
            line-height: 19px;
        }
    }
}

.ratingHr {
    width: calc(100% - 2px);
    margin: 0;
    border: 1px solid rgba(255, 255, 255, 0.01);
}


//Access
.accessModal {
    background: #FFFFFF;
    box-shadow: 0 3px 15px rgba(20, 67, 114, 0.08);
    border-radius: 15px;
    padding: 30px;
    max-height: 90%;
    //width: min(90%, 697px);
    max-width: 90%;
}

.accessContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.accessHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            cursor: pointer;
        }
    }

    h3 {
        font-size: 22px;
        line-height: 27px;
        font-family: "gilroy7", sans-serif;
        margin: 0;
    }

    span {
        font-size: 15px;
        line-height: 17px;
        color: var(--main-gray);
    }
}

.accessCardsContainer {
    background: rgba(255, 255, 255, 0.5);
    //backdrop-filter: blur(17.5px);
    border-radius: 26px;
    padding: 14px 22px;
    display: flex;
    gap: 36px;
    font-family: "SF5", sans-serif;

    @media (max-width: 800px) {
        flex-wrap: wrap;
        overflow-y: auto;
        height: calc(100vh - 254px);
        justify-content: center;

        &::-webkit-scrollbar {
            width: 0;
        }
    }
}

.accessCard {
    background: #FFFFFF;
    box-shadow: 0 3px 15px rgba(18, 62, 106, 0.15);
    border-radius: 26px;
    padding: 24px;
    width: 280px;
    box-sizing: border-box;

    h4 {
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 15px;
        margin-top: 0;
        font-family: "SF7", sans-serif;
    }

    .accessCardText {
        font-size: 15px;
        line-height: 130%;
        color: #848199;
    }
}

.accessCardRight {
    color: #231D4F;
}

.accessCardLeft {
    color: var(--bg-light-green);
}

.accessPricesBlock {
    display: flex;
    font-size: 15px;
    line-height: 18px;
    position: relative;
    margin-bottom: 12px;
    align-items: baseline;
    gap: 6px;

    img {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: -4px;
    }
}

.accessFirstPrice {
    opacity: 0.4;
    font-size: 24px;
    line-height: 46px;
    font-family: "SF7", sans-serif;
}

.accessSecondPrice {
    font-size: 24px;
    line-height: 46px;
    font-family: "SF7", sans-serif;
}

.accessPriceOnDate {
    color: var(--main-gray);
}

.accessBenefitsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 36px;
}

.accessBenefit {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #848199;
}

.accessButton {
    border-radius: 24px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    color: var(--white);
    font-size: 15px;
    line-height: 18px;
}

.accessBlueButton {
    background-color: #231D4F;
}

.accessGreenButton {
    background-color: var(--bg-light-green);
}


//locking
.lockingOverlay {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.87) 76.56%);
    backdrop-filter: blur(2.5px);
    width: calc(100vw - 304px);
    height: calc(100vh - 174px);
    left: unset;
    top: unset;
    background-color: unset !important;
    z-index: 9;

    @media (max-width: 1100px) {
        width: 100vw;
        height: calc(100vh - 157px);
    }
}

.lockingContent {
    background: unset;
    box-shadow: unset;
    display: flex;
    flex-direction: column;
    gap: 100px;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    justify-content: flex-end;
}

.lockingDataContainer {
    display: flex;
    gap: 12px;
    border-radius: 24px;
    background-image: url("../../assets/images/lockBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 360px;
    justify-content: space-between;
    align-items: center;
    padding: 36px;
    box-sizing: border-box;
    color: var(--white);

    .lockingImage {
        max-height: 100%;
        max-width: calc(100% - 500px);
    }
}

.lockingData {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
        font-size: 22px;
        line-height: 27px;
        font-family: "gilroy7", sans-serif;
        margin: 0;
    }

    span {
        font-size: 15px;
        line-height: 130%;
        font-family: "gilroy5", sans-serif;
    }
}

.lockingBenefitsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.lockingBenefit {
    display: flex;
    gap: 10px;
    align-items: center;
}

.lockingButtonContainer {
    display: flex;
    gap: 16px;

    button, a {
        height: 40px;
        border-radius: 24px;
        font-size: 15px;
        line-height: 18px;
        font-family: "SF5", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
    }

    .lockingPlansButton {
        color: #231D4F;
        width: 206px;
        background-color: var(--white);
        border: none;
    }

    .lockingCheckButton {
        color: var(--white);
        display: flex;
        gap: 8px;
        width: 258px;
        background: inherit;
        border: 1px solid var(--white);
        filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.25));
    }
}
